import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Image from "./DynamicImage"
import GoogleMap from "./Map"

const Location = () => (
  <StaticQuery
    query={graphql`
      {
        wpPage(databaseId: { eq: 21 }) {
          title
          content
          featuredImage {
            node {
              ...fragmentImage
            }
          }
          ACF_Location {
            keyFacts {
              label
              value
              icon {
                ...fragmentImage
              }
            }
          }
          seo {
            ...WpYoastSEO
          }
        }
      }
    `}
    render={data => {
      let {
        wpPage: {
          title,
          content,
          ACF_Location: { keyFacts: facts },
        },
      } = data
      // console.log(facts)
      return (
        <section id="location">
          <div className="container">
            <div className="row">
              <div className="col-md-12 p-lr-0">
                <div className="row main">
                  <div className="col-md-7 map">
                    <GoogleMap />
                  </div>
                  <div className="col-md-5 content">
                    <div className="eyebrow underline">
                      {title && parse(title)}
                    </div>
                    <div>{content && parse(content)}</div>
                  </div>
                  <div className="col-md-12 key-facts">
                    <div className="eyebrow">Some key facts</div>
                  </div>
                  <div className="col-md-12 facts">
                    {facts &&
                      facts.map((fact, index = 1) => {
                        // let content = slide.text
                        // console.log(fact.icon)
                        return (
                          <div className="fact" key={index}>
                            <ul className="keyfacts">
                              <li className="icon">
                                {fact.icon && <Image data={fact.icon} />}
                              </li>
                              <li>{fact.label}</li>
                              <li>
                                <strong>{fact.value}</strong>
                              </li>
                            </ul>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }}
  ></StaticQuery>
)

export default Location
