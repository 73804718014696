import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import About from "../components/About"
import Express from "../components/Express"
import Features from "../components/Features"
import Footer from "../components/Footer/Footer"
import Hero from "../components/Hero"
import Layout from "../components/Layouts/layout"
import Location from "../components/Location"
import SEO from "../components/seo"

export default function Home(props) {
  let {
    location,
    data: {
      wpPage: {
        title,
        seo,
        language: { slug: lang },
      },
    },
  } = props

  return (
    <Layout location={location} title={parse(title)}>
      <SEO bodyClasses="page home" lang={lang} seo={seo} />
      <Hero />
      <About />
      <Location />
      <Features />
      <Express />
      <Footer location={location} />
    </Layout>
  )
}

export const gql_home = graphql`
  {
    wpPage(isFrontPage: { eq: true }) {
      title
      language {
        slug
      }
      seo {
        ...WpYoastSEO
      }
    }
  }
`
