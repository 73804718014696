import Axios from "axios"
import { Formik } from "formik"
import React from "react"
import * as Yup from "yup"

export default class Leasing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      success: false,
      error: false,
      message: "",
    }
  }

  render() {
    const validationSchema = Yup.object().shape({
      Name: Yup.string()
        .min(3, "Must be at least ")
        .max("50", "Too long to be a name!")
        .required("Must enter a name"),
      Job: Yup.string()
        .min(3, "Must be at least ")
        .max("50", "Too long to be a job title!")
        .required("Must enter a job title"),
      Company: Yup.string()
        .min(3, "Must be at least ")
        .max("50", "Too long to be a company name!")
        .required("Must enter a company name"),
      Email: Yup.string()
        .email("Must be a valid email address")
        .max("50", "Too long to be an email!")
        .required("Must enter an email address"),
      Phone: Yup.string()
        .min(7, "Must be at least ")
        .max("15", "Too long to be a number!")
        .required("Must enter a phone"),
      Message: Yup.string()
        .min(3, "Must be at least ")
        .max("500", "Too long to be a message!")
        .required("Must enter a message"),
    })

    return (
      <Formik
        initialValues={{
          Name: "",
          Job: "",
          Company: "",
          Phone: "",
          Email: "",
          Message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (this.state.success === true) {
            // console.log("prevented");
            return
          }
          setSubmitting(true)
          // console.log(values);
          // console.log(this.isSubmitting)
          let bodyFormData = new FormData()
          bodyFormData.set("Name", values.Name)
          bodyFormData.set("Job", values.Job)
          bodyFormData.set("Company", values.Company)
          bodyFormData.set("Phone", values.Phone)
          bodyFormData.set("Email", values.Email)
          bodyFormData.set("Message", values.Message)

          Axios.post(
            `${process.env.WORDPRESS_FORMS}/wp-json/contact-form-7/v1/contact-forms/167/feedback`,
            bodyFormData
          )
            .then(res => {
              this.setState({
                message: res.data.message,
              })
              if (res.data.status === "mail_sent") {
                this.setState({
                  success: true,
                  message: "Thanks, we have received your message.",
                })
                resetForm()
                this.brochureElement.click()
              } else {
                this.setState({
                  error: true,
                })
              }
            })
            .catch(err => {
              this.setState({
                success: false,
                error: true,
              })
            })
          setSubmitting(false)
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="contactus" onSubmit={handleSubmit}>
            {/* {JSON.stringify(values)} */}
            <div className="row no-gutters">
              <div className="col-md-12">
                <span className="wpcf7-form-control-wrap">
                  <input
                    className={`form-control text ${
                      touched.Name && errors.Name ? "is-invalid" : null
                    }`}
                    type="text"
                    name="Name"
                    id="name"
                    placeholder="Full name *"
                    onChange={handleChange}
                    value={values.Name}
                    onBlur={handleBlur}
                    required
                  />
                </span>
              </div>
              <div className="col-md-6 mt-3">
                <span className="wpcf7-form-control-wrap">
                  <input
                    className={`form-control text ${
                      touched.Job && errors.Job ? "is-invalid" : null
                    }`}
                    type="text"
                    name="Job"
                    id="job"
                    placeholder="Job title *"
                    onChange={handleChange}
                    value={values.Job}
                    onBlur={handleBlur}
                    required
                  />
                </span>
              </div>
              <div className="col-md-6 mt-3">
                <span className="wpcf7-form-control-wrap">
                  <input
                    className={`form-control text ${
                      touched.Company && errors.Company ? "is-invalid" : null
                    }`}
                    type="text"
                    name="Company"
                    id="company"
                    placeholder="Company *"
                    onChange={handleChange}
                    value={values.Company}
                    onBlur={handleBlur}
                    required
                  />
                </span>
              </div>
              <div className="col-md-6 mt-3">
                <span className="wpcf7-form-control-wrap">
                  <input
                    className={`form-control text ${
                      touched.Email && errors.Email ? "is-invalid" : null
                    }`}
                    type="email"
                    name="Email"
                    id="email"
                    placeholder="Email address *"
                    onChange={handleChange}
                    value={values.Email}
                    onBlur={handleBlur}
                    required
                  />
                </span>
              </div>
              <div className="col-md-6 mt-3">
                <span className="wpcf7-form-control-wrap">
                  <input
                    className={`form-control text ${
                      touched.Phone && errors.Phone ? "is-invalid" : null
                    }`}
                    type="text"
                    name="Phone"
                    id="phone"
                    placeholder="Mobile *"
                    onChange={handleChange}
                    value={values.Phone}
                    onBlur={handleBlur}
                    required
                  />
                </span>
              </div>
              <div className="col-md-12 mt-3">
                <span className="wpcf7-form-control-wrap">
                  <textarea
                    className={`form-control message ${
                      touched.Message && errors.Message ? "is-invalid" : null
                    }`}
                    cols="30"
                    rows="3"
                    name="Message"
                    id="message"
                    placeholder="Message *"
                    onChange={handleChange}
                    value={values.Message}
                    onBlur={handleBlur}
                    required
                  />
                </span>
              </div>
              <div className="col-md-12 mt-3">
                <span className="small">
                  Upon submission of the leasing enquiry form, a PDF of the full
                  leasing brochure will be downloaded to your device.
                </span>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-12">
                <button
                  type="submit"
                  aria-label="Submit the form"
                  className="wpcf7-form-control wpcf7-submit btn btn-default mr-5"
                  disabled={this.isSubmitting}
                >
                  Submit
                </button>
                {this.state.message.length > 0 && (
                  <span
                    className={`alert alert-${
                      this.state.success ? "success" : "danger"
                    }`}
                  >
                    {this.state.message}
                    <a
                      href="/pdf/SOLITAIRE_Digital_Brochure_Eng.pdf"
                      className="text-hide"
                      ref={brochure => (this.brochureElement = brochure)}
                      download
                    >
                      Download brochure
                    </a>
                  </span>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  }
}
