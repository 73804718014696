import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import Carousel from "./Carousel"
import Careers from "./Forms/Careers"
import Leasing from "./Forms/Leasing"
import Media from "./Forms/Media"

const Express = () => {
  let [activeLink, setActiveLink] = useState("leasing")

  const handleClick = e => {
    setActiveLink(e.currentTarget.dataset.name)
  }

  return (
    <StaticQuery
      query={graphql`
        {
          wpPage(databaseId: { eq: 27 }) {
            title
            content
            featuredImage {
              node {
                ...fragmentImage
              }
            }
            ACF_Gallery {
              gallery {
                image {
                  ...fragmentImage
                }
              }
            }
            seo {
              ...WpYoastSEO
            }
          }
        }
      `}
      render={data => {
        let {
          wpPage: {
            // title,
            // content,
            ACF_Gallery: { gallery },
          },
        } = data
        // console.log(gallery)
        return (
          <section id="express">
            <div className="container">
              <div className="row">
                <div className="col-md-12 p-lr-0">
                  <Carousel data={gallery} cssClass="express-carousel" />
                </div>
              </div>
              <div className="row content">
                <div className="col-md-12">
                  <ul className="nav-tabs">
                    <li className={activeLink === "leasing" ? "active" : null}>
                      <button data-name="leasing" onClick={e => handleClick(e)}>
                        Leasing
                      </button>
                    </li>
                    <li className={activeLink === "careers" ? "active" : null}>
                      <button data-name="careers" onClick={e => handleClick(e)}>
                        Careers
                      </button>
                    </li>
                    <li className={activeLink === "media" ? "active" : null}>
                      <button data-name="media" onClick={e => handleClick(e)}>
                        Media
                      </button>
                    </li>
                  </ul>
                  <div className="eyebrow underline">Express your interest</div>
                  <div
                    className={`leasing ${
                      activeLink === "leasing" ? "active" : "d-none"
                    }`}
                  >
                    <h2>Leasing</h2>
                    <div>
                      <Leasing />
                    </div>
                  </div>
                  <div
                    className={`careers ${
                      activeLink === "careers" ? "active" : "d-none"
                    }`}
                  >
                    <h2>Careers</h2>
                    <Careers />
                  </div>
                  <div
                    className={`express ${
                      activeLink === "media" ? "active" : "d-none"
                    }`}
                  >
                    <h2>Media</h2>
                    <div>
                      <Media />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      }}
    ></StaticQuery>
  )
}

export default Express
