import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Carousel from "./Carousel"

const Features = () => (
  <StaticQuery
    query={graphql`
      {
        wpPage(databaseId: { eq: 24 }) {
          title
          content
          featuredImage {
            node {
              ...fragmentImage
            }
          }
          ACF_Gallery {
            gallery {
              image {
                ...fragmentImage
              }
            }
          }
          seo {
            ...WpYoastSEO
          }
        }
      }
    `}
    render={data => {
      let {
        wpPage: {
          title,
          content,
          ACF_Gallery: { gallery },
        },
      } = data
      // console.log(gallery)
      return (
        <section id="features">
          <div className="container">
            <div className="row">
              <div className="col-md-12 p-lr-0">
                <Carousel data={gallery} cssClass="features-carousel" />
              </div>
            </div>
            <div className="row content">
              <div className="col-md-12">
                <div className="eyebrow underline">{title && parse(title)}</div>
                <div>{content && parse(content)}</div>
              </div>
            </div>
          </div>
        </section>
      )
    }}
  ></StaticQuery>
)

export default Features
