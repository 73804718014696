import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Image from "./DynamicImage"
// import HeroCarousel from "./HeroCarousel"

const Hero = () => (
  <StaticQuery
    query={graphql`
      {
        wpPage(isFrontPage: { eq: true }) {
          title
          content
          featuredImage {
            node {
              ...fragmentImage
            }
          }
          ACF_Gallery {
            gallery {
              image {
                ...fragmentImage
              }
            }
          }
          seo {
            ...WpYoastSEO
          }
        }
      }
    `}
    render={data => {
      let {
        wpPage: {
          content,
          featuredImage: { node: image },
          // ACF_Gallery: { gallery },
        },
      } = data
      // console.log(image)
      return (
        <section id="hero">
          <div className="container">
            <div className="row content">
              <div className="col-md-12">
                <h1>
                  <img src="/images/solitaire.png" alt="Solitaire" />
                </h1>
                <div className="lead">{content && parse(content)}</div>
                <a
                  href="#express"
                  className="button"
                  style={{ display: "none" }}
                >
                  Express your interest
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 p-m-0">
                {/* <HeroCarousel data={gallery} cssClass="hero-carousel" /> */}
                <section className="flex middle">
                  <div className="video-wrapper">
                    <Image data={image} classes="poster" />
                    <iframe
                      title="Solitaire Riyadh"
                      src={`https://player.vimeo.com/video/552255537?background=1&autoplay=1&loop=1&byline=0&title=0&mute=1`}
                      frameBorder="0"
                    ></iframe>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <a className="scroll-btn" href="#about">
            Scroll down
          </a>
          <a href="/ar/" id="language">
            <img src="/images/arabic.png" alt="Arabic" />
          </a>
        </section>
      )
    }}
  ></StaticQuery>
)

export default Hero
